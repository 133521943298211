body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

.swal2-container {
  z-index: 100000000000 !important; }

[class*="--ResultsAttribution"] {
  display: none; }

#iframe-container {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  padding-top: 56.25%; }
  #iframe-container > iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; }

.home-welcome-page {
  text-align: center; }
  .home-welcome-page .app {
    text-align: center; }
  .home-welcome-page .rekit-logo {
    animation: app-logo-spin infinite 10s linear;
    height: 80px; }
  .home-welcome-page .app-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
    overflow: hidden;
    position: relative; }
  .home-welcome-page .app-title {
    font-size: 1.5em; }
  .home-welcome-page .app-intro {
    font-size: large; }
  .home-welcome-page ul,
  .home-welcome-page li {
    list-style: none;
    margin: 0;
    padding: 0; }
  .home-welcome-page ul {
    margin-top: 20px; }
  .home-welcome-page li {
    margin-top: 10px; }
  .home-welcome-page a {
    color: #0288d1;
    text-decoration: none; }
    .home-welcome-page a:hover {
      text-decoration: underline; }
  .home-welcome-page p.memo {
    width: 500px;
    color: #999;
    font-size: 12px;
    line-height: 150%;
    margin: auto; }

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.makeStyles-root-1 {
  min-width: 600px; }

.notifications-section-content-list .scrollbar-container {
  background-color: transparent !important; }

.notifications-all-notifications {
  padding-top: 0 !important; }
