@import './mixins';

// Here you put all global css rules.

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

// fix on swal must be on top of everything else including the MuiDialog
.swal2-container
{
  z-index: 100000000000 !important;
}

[class*="--ResultsAttribution"] {
    display: none;
}
